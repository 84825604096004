export  const categories = [
    {
      title: "General Information",
      items: [
        {
          question: "Who is a Virtual Startup Campus?",
          answer: "We are the only Job Focussed New Generation Training Platform in India to offer Corporate Trainings on both Technical and Non Technical Education. Students can build career in various domains attending these training sessions and getting certificates to showcase the skills learned."
        },
        {
          question: "What industries do you serve?",
          answer: "Our products/services are tailored to meet the needs of students to get on demand jobs in emerging sectors or with more equipped tool training that helps them stay relevant."
        },
        {
          question: "How long have you been in business?",
          answer: "[Company Name] was founded in [year]. Since then, we've grown to serve customers globally with a focus on [unique selling points]."
        },
        {
          question: "Do you have a mobile app?",
          answer: "Yes, you can download our app on both iOS and Android. Search for “[App Name]” in your app store."
        },
        {
          question: "Do you have physical locations?",
          answer: "Currently, we operate online only, but we ship or provide services to [regions, e.g., the U.S., Europe, Asia]."
        }
      ]
    },
    {
      title: "Account and Profile Management",
      items: [
        {
          question: "How do I create an account?",
          answer: "Click the “Sign Up” button on our homepage, fill in the required details, and verify your email to complete registration."
        },
        {
          question: "Is it free to create an account?",
          answer: "Yes, creating an account with us is entirely free."
        },
        {
          question: "How do I reset my password?",
          answer: "If you forgot your password, click on “Forgot Password” on the login page, and we’ll send you a link to reset it."
        },
        {
          question: "Can I change my email address or username?",
          answer: "Yes, go to your account settings to update your email or username. Contact support if you encounter any issues."
        },
        {
          question: "How do I delete my account?",
          answer: "Please reach out to our support team at [support email] to delete your account."
        },
        {
          question: "What should I do if I suspect my account has been compromised?",
          answer: "Contact us immediately at [security email or phone] so we can secure your account and guide you through the next steps."
        }
      ]
    },
    {
      title: "Products and Services",
      items: [
        {
          question: "What types of products/services do you offer?",
          answer: "We offer [list main products or services briefly]. Visit our Products/Services page for detailed information."
        },
        {
          question: "Do you offer gift cards or vouchers?",
          answer: "Yes, we offer digital gift cards that can be purchased and redeemed through our website."
        },
        {
          question: "Are there any free products/services I can try?",
          answer: "Yes, we offer a free [product/service trial or limited version]. Check our Free Trial page for more details."
        },
        {
          question: "Do you offer a satisfaction guarantee?",
          answer: "Yes, we stand by our products/services and offer a [number of days]-day satisfaction guarantee. Contact us if you’re not fully satisfied."
        },
        {
          question: "Can I book a demo or consultation?",
          answer: "Absolutely! Schedule a demo/consultation with our team by visiting the [Demo/Consultation] page."
        }
      ]
    },
    {
      title: "Pricing and Billing",
      items: [
        {
          question: "Where can I find your pricing?",
          answer: "Visit our Pricing page to see our different plans and products available."
        },
        {
          question: "Are there any hidden fees?",
          answer: "No, we are transparent with our pricing, and all fees are disclosed at checkout."
        },
        {
          question: "Do you offer payment plans or financing options?",
          answer: "Yes, for certain products, we offer flexible payment plans. Check our Payment Options page or contact us for more details."
        },
        {
          question: "Can I receive an invoice for my purchase?",
          answer: "Yes, you’ll receive an invoice via email after your purchase is complete. If you need a physical copy, let us know."
        },
        {
          question: "How can I update my billing information?",
          answer: "Log in to your account, navigate to billing settings, and make the necessary updates."
        },
        {
          question: "Do you charge for international payments?",
          answer: "We don’t charge extra fees, but your bank may apply foreign transaction fees. Contact your bank for details."
        }
      ]
    },
    {
      title: "Shipping, Delivery, and Fulfillment",
      items: [
        {
          question: "Do you ship internationally?",
          answer: "Yes, we ship worldwide. Shipping costs are calculated based on your location and will be displayed at checkout."
        },
        {
          question: "How long does shipping take?",
          answer: "Shipping typically takes [shipping duration] within [region], and international shipping times may vary. See our Shipping Policy for details."
        },
        {
          question: "How can I track my order?",
          answer: "After your order is shipped, you’ll receive a tracking number via email to monitor your package’s progress."
        },
        {
          question: "Do you offer expedited shipping?",
          answer: "Yes, we offer expedited shipping options for faster delivery at an additional cost."
        },
        {
          question: "My order hasn’t arrived; what should I do?",
          answer: "If your order is delayed beyond the estimated delivery time, please reach out to our support team."
        },
        {
          question: "What if my order is damaged or missing items?",
          answer: "If you receive a damaged item or your order is incomplete, please contact us within [number of days] for a resolution."
        }
      ]
    },
    {
      title: "Returns and Refunds",
      items: [
        {
          question: "What is your return policy?",
          answer: "You can return most items within [number of days] of delivery. Items must be unused and in their original packaging."
        },
        {
          question: "How do I initiate a return?",
          answer: "To initiate a return, contact our support team with your order number and reason for the return."
        },
        {
          question: "How long does it take to process a refund?",
          answer: "Refunds are typically processed within [number of days] after we receive the returned item."
        },
        {
          question: "Can I exchange an item?",
          answer: "Yes, exchanges are available for certain items. Contact us to start the exchange process."
        },
        {
          question: "Are there any items that are non-returnable?",
          answer: "Some items, such as [mention restricted items], are not eligible for returns. Please refer to our Return Policy."
        }
      ]
    },
    {
      title: "Technical Support and Troubleshooting",
      items: [
        {
          question: "I forgot my password. How do I reset it?",
          answer: "Click “Forgot Password” on the login page, and follow the instructions to reset your password."
        },
        {
          question: "How can I update my profile information?",
          answer: "Log in to your account, go to “Profile Settings,” and update your information as needed."
        },
        {
          question: "Why am I having trouble accessing the website?",
          answer: "If you're having trouble, try clearing your browser cache or switching browsers. Contact support if the issue persists."
        },
        {
          question: "What should I do if a page isn’t loading?",
          answer: "Try refreshing the page or visiting later. If the issue continues, report it to our support team."
        }
      ]
    },
    {
      title: "Privacy and Security",
      items: [
        {
          question: "Is my information safe on your website?",
          answer: "Yes, we use secure encryption and follow best practices to ensure your data is protected."
        },
        {
          question: "Do you sell or share my information with third parties?",
          answer: "We respect your privacy and will never sell or share your data without consent. Please see our Privacy Policy."
        },
        {
          question: "How do I unsubscribe from your marketing emails?",
          answer: "Click “Unsubscribe” at the bottom of any marketing email, or adjust your preferences in your account settings."
        },
        {
          question: "Can I download my data or request its deletion?",
          answer: "Yes, you may request a copy of your data or its deletion by contacting us at [privacy email]."
        }
      ]
    },
    {
      title: "Discounts and Promotions",
      items: [
        {
          question: "Do you offer any discounts for new customers?",
          answer: "Yes, new customers can enjoy a [mention any discount or coupon for new sign-ups]."
        },
        {
          question: "How do I apply a promo code?",
          answer: "Enter your promo code during checkout in the “Discount Code” box, and the discount will be applied automatically."
        },
        {
          question: "Can I combine multiple discounts?",
          answer: "Unfortunately, only one discount code may be used per order unless otherwise specified."
        },
        {
          question: "How often do you run promotions?",
          answer: "We run seasonal promotions, so keep an eye on our website or subscribe to our newsletter for updates."
        }
      ]
    },
    {
      title: "Subscription Plans and Cancellations",
      items: [
        {
          question: "How do I subscribe to your service?",
          answer: "Visit our Subscription page, choose a plan, and complete your registration and payment."
        },
        {
          question: "Can I upgrade or downgrade my plan?",
          answer: "Yes, you can modify your plan at any time by going to your account settings."
        },
        {
          question: "How do I cancel my subscription?",
          answer: "To cancel, go to your account settings or contact us. Ensure you do so before the next billing cycle to avoid charges."
        },
        {
          question: "Will I lose access to my data if I cancel?",
          answer: "You’ll have access to your data until the end of your billing cycle. Afterward, certain features may be limited."
        }
      ]
    },
    {
      title: "Educational and Training Services",
      items: [
        {
          question: "Do you provide certificates upon course completion?",
          answer: "Yes, you will receive a certificate after completing a course, which can be accessed through your account."
        },
        {
          question: "Can I access my course materials offline?",
          answer: "Course materials are available for download in some formats, depending on the course. Please check the course details for more information."
        },
        {
          question: "Are your courses self-paced or scheduled?",
          answer: "Most courses are self-paced, but some may have scheduled live sessions."
        },
        {
          question: "Do you offer group discounts for educational institutions?",
          answer: "Yes, we provide group discounts for institutions. Contact us for bulk pricing options."
        }
      ]
    }
  ];