import "../../stylesheets/carrerpage.css";
import { Typography, Button } from '@material-ui/core';
import { BsArrowRight } from 'react-icons/bs';
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Fade from '@mui/material/Fade';
import Loader from '../Career/Loader.jsx';
import { useMediaQuery } from "@mui/material";
import axios from 'axios';
import { validateEmail } from "../auth/validateEmail.js";
import { useNavigate } from 'react-router-dom';
import { POST_PARTNER_CLG } from "../../api/partner.js";

const PartnerCollege = () => {
  const navigate = useNavigate();
  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orgName, setOrgName] = React.useState('');
  const [repName, setRepName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [designation, setDesignation] = React.useState('');
  const [whatsapp, setWhatsapp] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+1'); // Default to USA
  const [purpose, setPurpose] = React.useState('');
  const [proposal, setProposal] = React.useState(null);
  const [emailError, setEmailError] = React.useState('');
  const [numberError, setNumberError] = React.useState('');
  const [thankYouMessage, setThankYouMessage] = React.useState(''); // New state for thank you message
  const isMobile = useMediaQuery('(max-width:838px)');

  const countries = [
    { code: '+1', label: 'USA' },
    { code: '+1', label: 'Canada' },
    { code: '+91', label: 'India' },
    { code: '+44', label: 'United Kingdom' },
    { code: '+61', label: 'Australia' },
    { code: '+81', label: 'Japan' },
    { code: '+49', label: 'Germany' },
    { code: '+33', label: 'France' },
    { code: '+39', label: 'Italy' },
    { code: '+34', label: 'Spain' },
    { code: '+86', label: 'China' },
    { code: '+82', label: 'South Korea' },
    { code: '+55', label: 'Brazil' },
    { code: '+7', label: 'Russia' },
    { code: '+27', label: 'South Africa' },
    { code: '+60', label: 'Malaysia' },
    { code: '+65', label: 'Singapore' },
    { code: '+62', label: 'Indonesia' },
    { code: '+41', label: 'Switzerland' },
    { code: '+351', label: 'Portugal' },
    { code: '+61', label: 'New Zealand' },
    { code: '+45', label: 'Denmark' },
    { code: '+353', label: 'Ireland' },
    { code: '+31', label: 'Netherlands' },
    { code: '+46', label: 'Sweden' },
    { code: '+47', label: 'Norway' },
    { code: '+358', label: 'Finland' },
    { code: '+36', label: 'Hungary' },
    { code: '+381', label: 'Serbia' },
    { code: '+358', label: 'Iceland' },
    { code: '+389', label: 'North Macedonia' },
    { code: '+373', label: 'Moldova' },
    { code: '+380', label: 'Ukraine' },
    { code: '+371', label: 'Latvia' },
    { code: '+370', label: 'Lithuania' },
    { code: '+372', label: 'Estonia' },
    { code: '+36', label: 'Hungary' },
    { code: '+994', label: 'Azerbaijan' },
    { code: '+90', label: 'Turkey' },
    { code: '+20', label: 'Egypt' },
    { code: '+961', label: 'Lebanon' },
    { code: '+962', label: 'Jordan' },
    { code: '+966', label: 'Saudi Arabia' },
    { code: '+971', label: 'United Arab Emirates' },
    { code: '+20', label: 'Egypt' },
    { code: '+254', label: 'Kenya' },
    { code: '+251', label: 'Ethiopia' },
    { code: '+233', label: 'Ghana' },
    { code: '+234', label: 'Nigeria' },
    { code: '+64', label: 'New Zealand' },
];

  const validate = () => {
    if (orgName.length <= 0 || repName.length <= 0 || designation.length <= 0) return false;
    else if (whatsapp.length !== 10) return false;
    else if (!validateEmail(email)) return false;
    else return true;
  };

  const handleSubmit = () => {
    if (validate()) {
      const data = new FormData();
      data.append('collegeName', orgName);
      data.append('name', repName);
      data.append('email', email);
      data.append('designation', designation);
      data.append('whatsapp', `${countryCode}${whatsapp}`); // Combine country code and number
      data.append('source', purpose);
      setLoading(true);
      axios
        .post(POST_PARTNER_CLG, data) // Ensure this endpoint is correct
        .then(response => {
          setLoading(false);
          setClicked(true);
          setThankYouMessage('Thank you for your submission! We will get back to you shortly.'); // Set thank you message
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      alert('Entered Wrong Data');
    }
  };

  return (
    <div className="career-main-page"
      style={{
        flexDirection: isMobile && 'column'
      }}
    >
      {!isMobile && <Fade in={true} timeout={2000}>
        <div
          className="career-page1"
          style={{
            width: isMobile && '100%'
          }}
        >
          <div style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Typography variant={!isMobile ? "h2" : 'h4'}
              style={{
                color: '#000',
                fontWeight: 'bold',
                fontFamily: 'Poppins',
                marginBottom: '10px',
              }}>For College Partnership</Typography>
            <Typography variant="subtitle1"
              style={{
                color: '#000',
                fontFamily: 'Poppins',
                marginBottom: '10px'
              }}>For Colleges to Partner with Virtual startup campus.</Typography>
            <Button
              variant="contained"
              onClick={(e) => {
                clicked && navigate('/');
              }}
              style={{
                justifySelf: 'center',
                background: '#6A58C6',
                width: '100%',
                borderRadius: '10px',
                fontFamily: 'Poppins',
                fontSize: '20px'
              }}
            >{clicked ? 'Home' : 'Connect Now'} &nbsp; {!clicked && <BsArrowRight fontSize='20px' fontWeight='bold' />} </Button>
          </div>
        </div>
      </Fade>}
      <Fade in={true} timeout={3000}>
        <div
          className="career-page2"
          style={{
            width: isMobile && '100%',
            height: isMobile ? '100%' : '600px'
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: !isMobile ? 1 : 0.5 },
              margin: '2%',
              borderRadius: '10px',
              background: '#6A58C6',
              height: '100%',
              padding: '2% 10% 5% 10%',
              display: clicked && 'flex',
              justifyContent: clicked && 'center',
              alignItems: clicked && 'center',
              boxShadow: '2px 2px 5px #6A58C6'
            }}
            noValidate
            autoComplete="off"
          >
            {loading && <Loader />}
            {!loading && clicked && (
              <Typography variant="h5"
                style={{
                  textAlign: "center",
                  fontFamily: 'Poppins',
                  color: '#fff',
                  marginTop: '40px'
                }}
              >
                {thankYouMessage} {/* Display thank you message */}
              </Typography>
            )}
            {!loading && !clicked &&
              <>
                <Typography variant="h4"
                  style={{
                    textAlign: "center",
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    color: '#fff',
                    marginBottom: '40px'
                  }}
                >
                  College Partner Form
                </Typography>
                <div>
                  <div style={{
                    display: 'flex',
                    flexDirection: !isMobile ? 'row' : 'column',
                    width: '100%',
                  }}>
                    <TextField
                      required
                      label="College Name"
                      InputLabelProps={{
                        style: { color: '#000', fontFamily: 'Poppins' }
                      }}
                      style={{
                        background: '#fff',
                        borderRadius: '10px',
                        borderColor: '#000',
                        width: !isMobile ? '50%' : '100%'
                      }}
                      onChange={(e) => setOrgName(e.target.value)}
                    />
                    <TextField
                      required
                      label="Your Name"
                      InputLabelProps={{
                        style: { color: '#000', fontFamily: 'Poppins' }
                      }}
                      style={{
                        background: '#fff',
                        borderRadius: '10px',
                        borderColor: '#000',
                        width: !isMobile ? '50%' : '100%'
                      }}
                      onChange={(e) => setRepName(e.target.value)}
                    />
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: !isMobile ? 'row' : 'column',
                    width: '100%'
                  }}>
                    <TextField
                      required
                      label="Your Email"
                      type="email"
                      InputLabelProps={{
                        style: { color: '#000', fontFamily: 'Poppins' }
                      }}
                      style={{
                        background: '#fff',
                        borderRadius: '10px',
                        borderColor: '#000',
                        width: !isMobile ? '50%' : '100%'
                      }}
                      onChange={(e) => {
                        if (!validateEmail(e.target.value) && e.target.value.length > 0) setEmailError('Enter A Correct Email');
                        else setEmailError('');
                        setEmail(e.target.value);
                      }}
                      helperText={emailError}
                    />
                    <TextField
                      required
                      label="Your Designation"
                      InputLabelProps={{
                        style: { color: '#000', fontFamily: 'Poppins' }
                      }}
                      style={{
                        background: '#fff',
                        borderRadius: '10px',
                        borderColor: '#000',
                        width: !isMobile ? '50%' : '100%'
                      }}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: !isMobile ? 'row' : 'row',
                    width: '100%',
                    alignItems: 'center'
                  }}>
                    <select
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      style={{
                        background: '#fff',
                        borderRadius: '10px',
                        borderColor: '#000',
                        width: '30%',
                        marginLeft: '5%',
                        padding: '14px', // Match padding with TextField
                        fontFamily: 'Poppins',
                        height: 60
                      }}
                    >
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.label} ({country.code})
                        </option>
                      ))}
                    </select>
                    <TextField
                      required
                      label="Whatsapp"
                      type="number"
                      InputLabelProps={{
                        style: { color: '#000', fontFamily: 'Poppins' }
                      }}
                      style={{
                        background: '#fff',
                        borderRadius: '10px',
                        borderColor: '#000',
                        width: '100%',
                        marginLeft: '5%'
                      }}
                      onChange={(e) => {
                        if (e.target.value.length !== 10 && e.target.value.length > 0) setNumberError('Whatsapp number should be 10 digits');
                        else setNumberError('');
                        setWhatsapp(e.target.value);
                      }}
                      helperText={numberError}
                    />
                  </div>
                  <TextField
                    required
                    label="How Did You Come To Know About Us?"
                    InputLabelProps={{
                      style: { color: '#000', fontFamily: 'Poppins' }
                    }}
                    style={{
                      background: '#fff',
                      borderRadius: '10px',
                      borderColor: '#000',
                      width: '100%',
                      margin: '10px 0'
                    }}
                    onChange={(e) => setPurpose(e.target.value)}
                  />
                  
                </div>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{
                    justifySelf: 'center',
                    background: '#000',
                    width: '100%',
                    borderRadius: '10px',
                    fontFamily: 'Poppins',
                    fontSize: '20px',
                    marginTop: '10px'
                  }}
                >Submit  &nbsp; <BsArrowRight fontSize='20px' fontWeight='bold' /></Button>
              </>
            }
          </Box>
        </div>
      </Fade>
    </div>
  );
}

export default PartnerCollege;
