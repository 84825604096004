const { BASE_URL } = require("./client");


const POST_PARTNER = BASE_URL + '/api/partner/post';
const POST_PARTNER_CLG = BASE_URL + '/api/partner/clg/post';









export {POST_PARTNER , POST_PARTNER_CLG};